import React, { lazy, Suspense } from 'react';
import { emporiModule } from '@empori/util';
import { render } from './util/render';
import { LoadingSkeleton } from './components/skeletons';

const Search = lazy(() =>
  import(/* webpackChunkName: "search" */'./components/search'));

emporiModule('primeflowLoaded', {
  primeflow: {
    InitSearch(element: string) {
      render(
        <Suspense fallback={<LoadingSkeleton />}>
          <Search />
        </Suspense>, document.querySelector(element)
      );
    },
    InitEpc(iframeEl: HTMLElement, email: string) {
      import(/* webpackChunkName: "primeflow-epc" */'./util/epc').then(fn => fn.default(iframeEl, email));
    },
    InitPartstream() {
      import(/* webpackChunkName: "primeflow-partstream" */'./util/partstream').then(fn => fn.default());
    }
  }
});